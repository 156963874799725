import { ComplianceParser } from "../../parsers/compliance-parser";
import { CaregiverDict } from "../../scripts/messages/caregiver";
import { ComplianceResponse, ComplianceStatsResponse } from "../../scripts/messages/compliance";
import { CaregiverId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";

//! @ngInject
export class CompService {
  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService
  ) {}

  fetch = (caregiversMap: CaregiverDict) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/compliance`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<ComplianceResponse>(url).then((res) => {
      const map = ComplianceParser.parseComplianceDocumentMap(res.data.documents);

      return {
        items: res.data.items.map((item) => ComplianceParser.parseCaregiverComplianceItem(item, map, caregiversMap)),
        documents: res.data.documents.map(ComplianceParser.parseComplianceDocument),
      };
    });
  };

  fetchStats = () => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/compliance_stats`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<ComplianceStatsResponse>(url).then((res) => res.data);
  };

  sendReminders = (caregiverIds: CaregiverId[], message: string) => {
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/compliance/reminders`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.post(url, { caregiverIds, message });
  }
}
